@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.NavigationItem {
    display: block;
    width: 100%;
    margin: 10px 0;
    box-sizing: border-box;

    a {
        display: block;
        text-decoration: none;
        width: 100%;
        box-sizing: border-box;
        color: $grey;

        &:hover,
        &:active/* ,
        &.active */ {
            color: $green;
            font-weight: 500;
        }
    }
}

@include breakpoint(desktop) {
    .NavigationItem {
        margin: 0;
        height: 100%;
        width: auto;
        padding: .7rem 1rem;

        a {
            display: flex;
            align-items: center;
            position: relative;
            height: 100%;
            color: $primary-color;
            @include transition(all, .5s, ease);

/*             &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                display: block;
                width: 0;
                height: 4px;
                background-color: $green;
                @include transition(all, .5s, ease);
            }

            &:hover:after,
            &:active:after,
            &.active:after {
                width: 100%;
            } */
        }
    }
}

@include breakpoint(laptopDesktop) {
    .NavigationItem {
        font-size: .9rem;
        padding: .4rem .5rem;
    }
}