.SideItem {
    margin-bottom: 15pt;
    word-break: break-word;
    overflow-wrap: break-word;

    .SideRepeatedItem {
        padding-bottom: 7pt;
        line-height: 1.5;

        .Level {
            height: 13px;
        }
    }
}

.ContactItem {
    display: flex;
    margin: 5px 0;
    word-break: break-all;

    svg {
        margin-right: 0.5rem;
    }
}

.ContactLine {
    margin-bottom: 7pt;

    span {
        display: inline-block;

        &:not(:last-of-type)::after {
            display: inline-block;
            margin-right: 0.5em;
            content: ",";
        }

        &.PhoneNumber {
            white-space: nowrap;
        }
    }
}

.SectionWrap {
    margin-bottom: 5pt;
}

.SectionTitleWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    margin-bottom: 7pt;

    > svg {
        order: 1;
        margin-right: 5px;
    }
}


.RepeatedItem {
    margin-bottom: 5pt;

    .Title {
        font-weight: 700;
        line-height: 1.3;
    }
}

.ProgressLine {
    width: '100%';
    height: 10pt;
}