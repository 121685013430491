@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/variables.scss';

.NavigationItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
    @include transition(all, .5s, ease);
}

.LinkButton {
    padding: .75rem 1.5rem;
    margin-left: 1rem;
    background: $green;
    border-radius: 4px;
    box-shadow: 0px 3px 8px rgba(95, 95, 122, 0.2);
    @include transition(all, .5s, ease);
    a {
        font-size: 1.14rem;
        /* font-weight: 600; */
        color: #fff;

        &:hover {
            color: #fff; 
        }
    }
    &:hover {
        background-color: $primary-color;
    }
}

@include breakpoint(desktop) {
    .NavigationItems {
        flex-flow: row;
    }
}

@include breakpoint(desktopDown) {
    .LinkButton {
        margin: 10px auto;
    }
}