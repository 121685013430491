@import '../../../assets/styles/variables.scss';

.Dialog {
    h2 {
        margin-bottom: .5em;
    }

    .CloseDialogButton {
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 5px;
    
        svg {
            fill: $light-grey;
        }
    }

    a {
        display: inline-block;
        margin-top: 0.5rem;
        color: $light-grey;
        text-decoration: underline;
        cursor: pointer;
        text-align: center;
    }
}