@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.AccountDropdown {
    z-index: 1000;
}
.AccountNavigationButton {
    text-transform: initial;
    color: $primary-color;
    font-family: $font-stack;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.2;
    letter-spacing: 0;
}

.AccountNavigationWrap {
    /* background: #FAFBFF; */
    background-color: #fff;
    box-shadow: 0px 3px 4px rgba(45, 48, 68, 0.18);
    border-radius: 0px 0px 4px 4px;
}

.AccountNavigationItems {
    padding: .75rem 2rem 1rem 1rem;

    li {
        padding: .25rem 0;
        line-height: 1.5;
    }
}

@include breakpoint(desktopDown) {
    .AccountNavigationWrap {
        background: transparent;
        box-shadow: none;
        margin: 0;
        padding: 0;
        ul {
            margin: 0;
            padding: 0;

            li {
                margin: 0;
                padding: 0;
            }
        }
    }

    .AccountNavigationItems {
        margin: 0;
        padding: 0;
        li {
            padding: 0;
        }
    }

    .AccountNavigationTitle {
        font-weight: bold;
        font-size: 1.3rem;
        color: $primary-color;
        margin-bottom: 10px;
    }
}

@include breakpoint(laptopDesktop) {
    .AccountNavigationButton {
        font-size: .85rem;
        padding: .4rem;
    }
}