$font-stack: 'Lato', 'Helvetica Neue', sans-serif;

$primary-color: #677089;
$grey: #333;
$dark-grey: #5C6783;
$light-grey: #8190AA;

$green: #10b58c;
$light-green: #24D39F;

$red: #F03153;
$orange: #FFA133;
$yellow: #F6C823;


$bg-color: #F6F8FC;
/* $bg-color: #E5E5E5; */

$header-height: 55px;