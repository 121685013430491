@import '../../../assets/styles/variables.scss';

.FormControl {
    width: 100%;
    margin: .5rem 0;
    font-family: $font-stack;

    .Input {
        font-size: 1.1rem;
        padding: 17px 14px;
        &:hover,
        &:focus {
            border-color: $primary-color;
            font-family: $font-stack;
        }
    }
    
    .Label {
        display: flex;
        align-items: center;
        position: relative;
        text-align: left;
        transform: translate(0, -6px);
        color: $light-grey;
        font-family: $font-stack;

        svg {
            margin-right: .5rem;
        }
    }
}

.ValidationError {
    color: red;
    font-size: .85rem;
    text-align: left;
    margin-top: .25em;
}