@import '../../assets/styles/mixins.scss';

.TemplatesWrap {
    position: relative;
    text-align: center;
    padding: 0 2rem;

    .Button {
        position: absolute;
        bottom: 17%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
        max-width: 300px;
    }
}

.TemplatesList {
    margin: 2rem auto 0;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly; */
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;
    @include transition(all, .5s, ease-in-out);


    .TemplateItem {
        display: flex;
        flex: 0 0 19%;
        position: relative;
        box-shadow: 0px 3px 3px rgba(45, 48, 68, 0.18);
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
        //margin-bottom: .5rem;
        @include transition(all, .5s, ease-in-out);
        img {
            max-width: 100%;
        }

        .TemplateButton {
            font-size: 1rem;
            visibility: hidden;
            opacity: 0;
            @include transition(all, .5s, ease-in-out);
            position: absolute;
            bottom: 1rem;
            left: 50%;
            transform: translateX(-50%);
            width: fit-content;
        }

        &:hover {
            .TemplateButton {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

.TemplatesListContentHover {
    .TemplateItem:not(:hover){
        opacity: 0.3;
    }
}

@include breakpoint(tablet) {
    .TemplatesWrap {
        padding: 0 1rem;
    }
    .TemplatesList {
        grid-template-columns: repeat(3, 1fr);
        /* .TemplateItem {
            flex: 0 0 31%;
        } */
    }
}

@include breakpoint(mobile) {
    .TemplatesList {  
        grid-template-columns: repeat(2, 1fr);  
        /* justify-content: space-between;
        .TemplateItem {
            flex: 0 0 48%;
            margin-bottom: 1rem;
        } */
    }
}