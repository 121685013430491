@import '../../assets/styles/mixins.scss';

.Document {
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: #fff;
    color: #000;
    /* box-shadow: 0 0 5px rgba(0,0,0,.4); */
    //box-shadow: 0px 0px 4px rgba(0, 0, 0, .25);
    border-radius: 5px;
    width: 8.26in;
    min-height: 11.69in;
    margin: 1rem auto;
    box-sizing: border-box;
    transform-origin: center;
    /* transform: translate(-50%, -52%) scale(0.6); */
    transform: translate(-50%, -52%) scale(0.8);
    text-align: left;
    overflow: hidden;
    display: flex;
    flex-direction: column;

/*     &:after {
        content: "";
        border-bottom: 1px dashed rgb(12, 25, 37);
        top: 11.69in;
        left: 0;
        position: absolute;
        width: 100%;
        height: 1px;
        z-index: 10;
    } */
    .BgTop,
    .BgBottom {
        /* z-index: 4;
        position: absolute;
        width: 100%;
        height: 5.85in; */
        /* z-index: 6; */
        position: absolute;
        width: 100%;
        height: 100%
    }

    .BgTop {
        top: 0;
        /* background-image: url(../../../assets/images/template/executive-bg-top.svg); */
        background-position: right top;
        background-repeat: repeat-y;
        background-size: auto 11.69in;
        /* opacity: 0.2; */
    }

    /* p {
        margin-bottom: 0;
    } */
}

p {
    margin-bottom: 0;
}

@include breakpoint(desktop) {
    .Document {
        transform: translate(-50%, -52%) scale(.7);
    }
}

@include breakpoint(desktop-lg) {
    .Document {
        transform: translate(-50%, -52%) scale(.85);
    }
}

@media (min-width: 1500px) {
    .Document {
        /* transform: translate(-50%, -52%) scale(.8); */
        transform: translate(-50%, -52%) scale(1);
    }
}

@media (min-width: 1700px) {
    .Document {
        /* transform: translate(-50%, -52%) scale(1); */
        transform: translate(-50%, -52%) scale(1.15);
    }
}

@media (min-width: 2000px) {
    .Document {
        /* transform: translate(-50%, -52%) scale(1); */
        transform: translate(-50%, -52%) scale(1.3);
    }
}

@media (min-width: 2500px) {
    .Document {
        /* transform: translate(-50%, -52%) scale(1.3); */
        transform: translate(-50%, -52%) scale(1.68);
    }
}

@media (min-width: 3000px) {
    .Document {
        /* transform: translate(-50%, -52%) scale(1.3); */
        transform: translate(-50%, -52%) scale(1.9);
    }
}

@include breakpoint(tablet) {
    .Document {
        transform: translate(-50%, -52%) scale(.5);
    }
}

@include breakpoint(mobile) {
    .Document {
        transform: translate(-50%, -52%) scale(.4);
    }
}

@include breakpoint(mobile-xs) {
    .Document {
        transform: translate(-50%, -52%) scale(.35);
    }
}