@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.Paper {
    padding: 1.7rem 1.3rem;
    box-shadow: 0px 1px 4px rgba(28, 28, 89, 0.08);
    margin-bottom: 1rem;
}

.Link {
    color: $green;
}

.Form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .Input {
        width: 48%;
    }
}

.FlexRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;
}

.Switch {
    margin: 0 0 0 auto;
}

@include breakpoint(tablet) {
    .Form {
        .Input {
            width: 100%;
        }
    }
    
    .Paper {
        h3 {
            margin-bottom: 0.5em;
        }
    }
}