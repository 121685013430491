@import '../../../assets/styles/variables.scss';

.SkillButtonWrap {
    border-radius: 2px;
    overflow: hidden;
    max-width: 100%;

    &.Novice {
        .SkillButton:first-child {
            background-color: $red;
        }        
        
        & + .Caption {
            color: $red;
        } 
    }
    
    &.Beginner {
        .SkillButton:first-child,
        .SkillButton:nth-child(2) {
            background-color: $orange;
        }        
        
        & + .Caption {
            color: $orange;
        } 
    }
    
    &.Skillful {
        .SkillButton:first-child,
        .SkillButton:nth-child(2),
        .SkillButton:nth-child(3) {
            background-color: $yellow;
        }        
        
        & + .Caption {
            color: $yellow;
        } 
    }
    
    &.Experienced {
        .SkillButton:not(:last-child) {
            background-color: $light-green;
        }        
        
        & + .Caption {
            color: $light-green;
        } 
    }

    &.Expert {
        .SkillButton {
            background-color: $green;
        }

        & + .Caption {
            color: $green;
        } 
    }

    &.Disabled {
        border-radius: 5px;
        .SkillButton {
            pointer-events: none;
            cursor: default;
            background-color: #F1F5FB;
        }

        & + .Caption {
            color: #DCE2F6;
        }
    }

    &.Disabled.Novice {
        .SkillButton:first-child {
            background-color: #E3E8F1;
        }  
    }

    &.Disabled.Beginner {
        .SkillButton:first-child,
        .SkillButton:nth-child(2) {
            background-color: #E3E8F1;
        }
    }

    &.Disabled.Skillful {
        .SkillButton:first-child,
        .SkillButton:nth-child(2),
        .SkillButton:nth-child(3) {
            background-color: #E3E8F1;
        }
    }
    &.Disabled.Experienced {
        .SkillButton:not(:last-child) {
            background-color: #E3E8F1;
        }
    }

    &.Disabled.Expert {
        .SkillButton {
            background-color: #E3E8F1;
        }
    }

    .SkillButton {
        display: block;
        width: 40px;
        height: 14px;
        padding: 0;
        background-color: #F1F5FB;
        border-radius: 0;
        border: none;
        &:not(:last-of-type) {
            border-right: 1px solid #E3E8F1;
            margin-right: 1px;
        }
    }
}

.Caption {
    text-transform: capitalize;
    margin-bottom: 0;
    margin-top: 0.5rem;
}