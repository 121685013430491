@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.Dashboard {
    h1 {
        font-weight: normal;
        font-size: 1.7rem;
        text-align-last: left;
        margin-bottom: 0;
    }

    h4 {
        font-weight: normal;
        border-bottom: 1px solid #DCE2F6;
        line-height: 2;
        margin-bottom: .75em;
    }

    a {
        display: inline-block;
        color: #fff;
    }
}

.DashboardTitle {
    border-bottom: 1px solid #EDF2FF;

    .ButtonWrap {
        text-align: right;
    }
}

.DashboardContent {
    width: auto;
    padding: 35px 0;

    h3 {
        font-size: 1.28rem;
        margin-bottom: .5rem;
    }
    
    .Button {
        margin: 1rem 0;
    }
}

.DashboardImage {
    display: block;
    margin: 0 auto;
}

.DashboardTitle {
    border-bottom: 1px solid #EDF2FF;
}

.DashboardCard {
    box-shadow: 0px 3px 4px rgba(45, 48, 68, 0.18);
    border-radius: 5px;
    padding: 30px 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;

    .CardIcon {
        margin: 0 auto 1rem;
        width: auto;
        max-height: 55px;
    }

    h5 {
        font-size: 1rem;
        margin-bottom: .5em;
    }

    p {
        font-size: .85rem;
        margin-bottom: 0;
    }

    a {
        display: block;
    }
}

.AddNewCard {
    position: relative;
    box-shadow: 0px 3px 4px rgba(28, 28, 89, 0.08);
    border-radius: 4px;
    text-align: center;
    max-width: 100%;
    width: 100%;
    height: 0;
    padding-bottom: 141%;
    background-color: #fff;

    .Content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        font-size: 0.9em;
    }

    .Link {
        color: $green;
    }
}

.ResumeCardWrap {
    @include transition(all, .5s, ease);

    img {
        cursor: pointer;
    }
    &:hover {
        opacity: 1;
        .ResumeControls {
            opacity: 1;
            visibility: visible;
        }
    }/* 
    &:hover ~ * {
        opacity: 0.3;
    } */

    .ResumeCardLink {
        display: flex;
        flex-direction: column;
        /* box-shadow: 0px 3px 4px rgba(28, 28, 89, 0.15); */
    }
}
    

.ResumeCard:hover + .ResumeControls {
        opacity: 1;
        visibility: visible;
}

.DashboardContentHover {
    .ResumeCardWrap:not(:hover){
        opacity: 0.3;
    }
}

.ResumeControls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 3px 4px rgba(28, 28, 89, 0.15);
    border-radius: 4px;
    padding: .5rem;
    width: 100%;
    margin: 10px auto 0;
    opacity: 0;
    visibility: hidden;
    @include transition(all, .5s, ease);

    .ControlLink {
        cursor: pointer;
        text-decoration: none;
        color: $primary-color;
        padding: .5rem;
        width: 100%;
        text-align: center;

        &:not(:last-child) {
            border-right: 1px solid #F5F7FF;
        }

        &:hover {
            color: $green;
        }

        &.Danger:hover {
            color: red;
        }
    }
}

.Button {
    display: block;
    width: auto;
    text-transform: none;
    background-color: $green;
    padding: .7rem 1.5rem;
    font-size: 1.1rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    
    &:hover {
        background-color: #677089;
    }
}

.ShareDialog {
    .Button {
        align-self: center;
    }

    .LinkInput {
        margin: 1rem 0;
        border: 0;
        outline: none;
        font-size: 1rem;
        line-height: 2;
        border-bottom: 1px solid $green;

        &:hover,
        &:active {
            border-bottom: 1px solid $light-green;
        }
    }

    p.Status {
        text-align: center;
        font-size: .9rem;
        color: $green;
        margin-top: .5rem;
    }
}

.DashboardCardWrap {
    a {
        display: block;
        height: 100%;
    }
}

@include breakpoint(mobile) {
    .Button {
        width: 100%;
        text-align: center;
    }
}

@include breakpoint(tablet) {
    .Dashboard {
        text-align: center;
        h1 {
            font-size: 1.3rem;
        }
    }

    .DashboardTitle {
        a {
            padding: .7rem .7rem;
        }
    }
}