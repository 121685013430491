@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.Button {
    display: block;
    width: auto;
    text-transform: none;
    background-color: $green;
    padding: .7rem 1.5rem;
    font-size: 1.1rem;
    &:hover {
        background-color: #677089;
    }
}

@include breakpoint(mobile) {
    .Button {
        width: 100%;
        text-align: center;
    }
}