@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.MobileSide {
    position: absolute;
    width: 100%;
    height: calc(100vh - #{$header-height});
    left: 0;
    top: $header-height;
    z-index: 1000;
    background-color: $bg-color;
    color: $green;
    padding: 32px 16px;
    box-sizing: border-box;
    @include transition(transform, .3s, ease-out);
    text-align: center;

    ul {
        display: flex;
        flex-direction: column;
    }

    li {
        width: auto;
        font-size: 1.3rem;
        margin: 10px auto;
    }

    &.Open {
        transform: translateX(0);
    }
    
    &.Close {
        transform: translateX(-100%);
    }

    .Logo {
        margin-bottom: 2rem;
    }

    hr {
        max-width: 320px;
        background-color: #DCE2F6;
        margin: 30px auto;
    }

    button {
        width: 300px;
    }
}

@include breakpoint(desktop) {
    .MobileSide {
        display: none;
    }
}

