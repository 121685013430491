@import '../../../assets/styles/variables.scss';

.SelectInput {
    width: 100%;
    .Label {
        position: relative;
        text-align: left;
        transform: translate(0, -6px) scale(1)!important;
        color: $light-grey;
        font-family: $font-stack;
        margin-bottom: 0.25em;
    }
    legend {
        display: none;
    }
}

.Label {
    transform: translate(0, 0) scale(1);
    margin-bottom: 5px;
}