@import '../../../assets/styles/variables.scss';

.FormControl {
    width: 100%;
    margin: .5rem 0;
    font-family: $font-stack;

    .Label {
        position: relative;
        text-align: left;
        transform: translate(0, -6px);
        color: $light-grey;
        font-family: $font-stack;
    }
}