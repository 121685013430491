@mixin transition($transition-property, $transition-time, $method) {
  -webkit-transition: $transition-property $transition-time $method;
  -moz-transition: $transition-property $transition-time $method;
  -ms-transition: $transition-property $transition-time $method;
  -o-transition: $transition-property $transition-time $method;
  transition: $transition-property $transition-time $method;
}
  
@mixin animation($properties...) {
  -webkit-animation: $properties;
  -o-animation: $properties;
  animation: $properties;
}

@mixin breakpoint($point) {
  @if $point==desktop-lg {
	@media (min-width: 1280px) {
	  @content;
	}
  } @else if $point==desktop {
	@media (min-width: 1025px) {
	  @content;
	}
  } @else if $point==tabletUp {
	  @media (min-width: 768px) {
		@content;
	  }
  } @else if $point==tablet-landscape {
	@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
	  @content;
	}
  } @else if $point==laptopDesktopDown {
	@media (max-width: 1400px) {
	  @content;
	}
  } @else if $point==laptopDesktop {
	@media (max-width: 1300px) and (min-device-width: 1025px) {
	  @content;
	}
  } @else if $point==desktopDown {
	@media (max-width: 1024px) {
	  @content;
	}
  } @else if $point==tabletLandscape {
	@media (max-width: 995px) {
	  @content;
	}
  } @else if $point==tablet {
	@media (max-width: 767px) {
	  @content;
	}
  } @else if $point==mobile {
	@media (max-width: 420px) {
	  @content;
	}
  } @else if $point==mobile-xs {
	@media (max-width: 320px) {
	  @content;
	}
  }
}