.UserImage {
    width: 150px;
    min-height: 150px;
    height: 150px;
    max-width: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
    flex: 1 0 23%;
}

.SectionWrap {
    margin-bottom: 30px;
}


.RepeatedItem {
    margin-bottom: 12px;

    &:not(:last-child) {
        padding-bottom: 12px;
        border-bottom: 1px dashed #ccc;;
    }
    
    .RepeatedTitleWrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .Delimeter {
        margin: 0 .25rem; 
    }
}

.FlexRow {
    display: flex;
    flex-wrap: wrap;

    .LanguagesItem,
    .SkillsItem {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-basis: 31%;
        margin-right: 3.5%;
        padding-bottom: 8px;
        border-bottom: 1px dashed #ccc;

        &:nth-child(3n + 3) {
            margin-right: 0%;
        }

        .LanguageTitle,
        .SkillTitle {
            font-weight: bold;
        }

        > * {
            order: 1;
        }
    }

    .LanguagesItem {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;
    }
}