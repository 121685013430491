.SectionWrap {
    margin-bottom: 5pt;
}

.SectionTitleWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    border-bottom: 2px solid #e4e4e4;
    margin-bottom: 10pt;
    padding-bottom: 3pt;

    > svg {
        order: 1;
        margin: 5px 5px 5px 0;
    }
}

.MainContentCell {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    max-width: 70%;
    width: 70%;
    p {
        line-height: 1.3;
    }

    ul {
        margin: .5rem 0;
        line-height: 1.3;
    }
}

.SideItem {
    margin-bottom: 15pt;

    .SideRepeatedItem {
        padding-bottom: 5pt;
        line-height: 1.7;

        .Level {
            height: 10pt;
        }
    }
}

.SideSubtitle {
    color: #8190AA;
    margin-top: 5pt;
}

.ProgressLine {
    width: '100%';
    height: 10pt;
}