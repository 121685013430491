.ContactLine {
    .ContactItem {
        display: flex;
        align-items: center;
        line-height: 2;

        a {
            color: inherit;
        }

        svg {
            margin-right: 0.5rem;
        }

        .ContactTitle {
            font-weight: 700;
            margin-right: 0.5rem;
        }
    }
}