@import './assets/styles/variables.scss';
@import './assets/styles/mixins.scss';

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

/* @import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Dancing+Script&family=Lato&family=Open+Sans&family=Overpass&family=Montserrat&family=Quicksand&family=Raleway&family=Roboto&family=Rubik&family=Rufina&family=Source+Serif+Pro&family=Ubuntu&family=Yellowtail&display=swap'); */
@import url('http://fonts.cdnfonts.com/css/marat-sans');

/* @font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src: local('Ubuntu'), url('https://cdn.jsdelivr.net/npm/@typopro/web-ubuntu@3.7.5/TypoPRO-Ubuntu-Regular.ttf') format('truetype');
}*/

* {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    height: 8px;
    width: 6px;
}
::-webkit-scrollbar-track-piece {
    background: #fff;
}
::-webkit-scrollbar-thumb:vertical,
::-webkit-scrollbar-thumb:horizontal {
    background: #E3E8F1;
    border-radius: 20px;
} 

input::placeholder {
    color: #677089 !important;
}

html {
    margin: 0;
    font-size: 16px;
    scroll-behavior: smooth;
}

body {
    position: relative;
    margin: 0;
    font-size: 16px;
    font-family: $font-stack;
    color: $primary-color; 
    background: $bg-color url('./assets/images/footer_bg.svg') no-repeat center bottom;
    background-size: 100%;

    &.dashboard {
        background: $bg-color url('./assets/images/dashboard_footer_bg.svg') no-repeat center bottom;
        background-size: 100%;
    }
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .25em;
    color: $primary-color;
}

p {
    margin-top: 0;
    margin-bottom: 1em;
    line-height: 1.5;
}

.slick-slide,
.slick-slide:focus,
.slick-slide:focus  div,
.slick-slide div {
    outline: none !important;
    border: none !important;
}

.slick-prev, .slick-next {
    width: auto;
    height: auto;
}

.slick-prev:before, 
.slick-next:before {
    display: none;
}

.slick-slider img {
    max-width: 100%;
    opacity: .5;
    transform: scale(.85);
    margin: 0 auto;
}

.center .slick-center img {
    opacity: 1;
    transform: scale(1);
    transition: all .5s ease;
}

.page-delimeter {
    display: block;
    min-height: 25px;
    width: 100%;
    order: 0 !important;
}

.page-line {
    border-bottom: 1px dashed rgb(12, 25, 37);
    left: 0;
    position: absolute;
    width: 100%;
    height: 1px;
    z-index: 10;
}

h1 {
    text-align: center;
    font-size: 2rem;
}

h2 {
    font-size: 1.4rem;
    color: $light-grey;
}

h4 {
    font-size: 1.14rem;
}

a {
    text-decoration: none;
}

@include breakpoint(laptopDesktopDown) {
    html,
    body {
        font-size: 14px;
    }

    .slick-prev,
    .slick-next,
    .slick-prev:hover, 
    .slick-prev:focus, 
    .slick-next:hover, 
    .slick-next:focus {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        width: 100px;
        height: 100px;
        line-height: 100px;
        padding: 10px;
        background-color: rgba(200, 192, 221, .12);
        border-radius: 50%;
        z-index: 100;
    }
    .slick-prev {
        left: -50px;
        align-items: flex-end;
    }
    .slick-next {
        right: -50px;
        align-items: flex-start;
    }
}

@include breakpoint(mobile) {
    body {
        background: $bg-color;
    }

    h1,
    h2 {
        font-size: 1.28rem;
    }

    .slick-prev,
    .slick-next {
        top: 40%;
    }
}

/* Tiny MCE Editor */
.tox.tox-tinymce {
    border: 1px solid #E3E8F1;
    border-radius: 5px;
    outline: none;
    @include transition(all, .5s, ease);

    &:focus,
    &:hover {
        border-color: $primary-color;
    }
}

.tox.tox-tinymce .tox-tbtn {
    height: 24px;
}

.tox.tox-tinymce .tox-tbtn.tox-tbtn--enabled,
.tox.tox-tinymce .tox-tbtn:hover {
    background: transparent;
    border: 0;
    box-shadow: none;
    color: $green;
}

.tox.tox-tinymce .tox-tbtn.tox-tbtn--enabled svg,
.tox.tox-tinymce .tox-tbtn:hover svg {
    fill: $green;
}

.tox.tox-tinymce .tox-tbtn svg {
    fill: $light-grey;
}

.tox.tox-tinymce .tox-toolbar, 
.tox.tox-tinymce .tox-toolbar__overflow, 
.tox.tox-tinymce .tox-toolbar__primary {
    background: none;
}

.tox.tox-tinymce:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
    border-right: 1px solid #E3E8F1;
}

.tox.tox-tinymce .tox-toolbar__group{
    margin: .5rem 0;
}

.tox-tbtn[aria-label="Insert/edit link"] .tox-icon svg {
    display: none;
}

/*Dimensions of svg icon:*/
.tox-tbtn[aria-label="Insert/edit link"] .tox-icon {
    display: list-item;
    list-style: none;
    width: 18px;
    height: 18px;
}

/*Svg icon (In base64):*/
[aria-label="Insert/edit link"].tox-tbtn .tox-icon {
    background-image: url('./assets/images/link_icon.svg');
    background-repeat: no-repeat;
    background-position: center center;
}

.tox-dialog,
.tox .tox-dialog__title {
    font-family: $font-stack;
}

.tox-dialog .tox-form .tox-form__group:nth-child(2) {
    display: none;
}

.tox .tox-dialog .tox-dialog__footer-end .tox-button:last-child {
    background-color: $green;
}

.react-datepicker-wrapper {
    max-width: 100%;
    width: 100%;
}

.react-datepicker-wrapper .MuiFormControl-root {
    width: 100%;
}

.react-datepicker-wrapper .react-datepicker__close-icon {
    right: 35px;
}

.react-datepicker-wrapper .react-datepicker__close-icon:after {
    background-color: rgba(0, 0, 0, 0.54);
}

.react-datepicker-popper {
    z-index: 100 !important;
}

.react-datepicker__month-container {
    font-family: $font-stack;
    font-size: 0.9rem;
}

.react-datepicker__month-container .react-datepicker__header {
    background-color: #fff;
    border-bottom: 1px solid $light-grey;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding: 8px;
    color: $green;
}

.react-datepicker__month-text {
    padding: 7px;
}

.react-datepicker__month-container .react-datepicker__month--selected,
.react-datepicker__month-container .react-datepicker__month--selected, 
.react-datepicker__month-container .react-datepicker__month--in-selecting-range, 
.react-datepicker__month-container .react-datepicker__month--in-range, 
.react-datepicker__month-container .react-datepicker__quarter--selected, 
.react-datepicker__month-container .react-datepicker__quarter--in-selecting-range, 
.react-datepicker__month-container .react-datepicker__quarter--in-range,
.react-datepicker__month-container .react-datepicker__day--keyboard-selected,
.react-datepicker__month-container .react-datepicker__month-text--keyboard-selected,
.react-datepicker__month-container .react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__month-container .react-datepicker__year-text--keyboard-selected {
    background-color: $light-green;
    border: none;
    outline: none;
}

.react-datepicker__month-container .react-datepicker__month-text.react-datepicker__month--selected:hover {
    background-color: $green;
}

.react-datepicker-popper { 
    z-index: 100 !important; 
}

.react-datepicker__year-wrapper {
    justify-content: space-around;
}

.react-datepicker__year .react-datepicker__year-text {
    padding: 5px;
}

.react-datepicker__year .react-datepicker__day--selected, 
.react-datepicker__year .react-datepicker__day--in-selecting-range, 
.react-datepicker__year .react-datepicker__day--in-range, 
.react-datepicker__year .react-datepicker__month-text--selected, 
.react-datepicker__year .react-datepicker__month-text--in-selecting-range, 
.react-datepicker__year .react-datepicker__month-text--in-range, 
.react-datepicker__year .react-datepicker__quarter-text--selected, 
.react-datepicker__year .react-datepicker__quarter-text--in-selecting-range, 
.react-datepicker__year .react-datepicker__quarter-text--in-range, 
.react-datepicker__year .react-datepicker__year-text--selected, 
.react-datepicker__year .react-datepicker__year-text--in-selecting-range, 
.react-datepicker__year .react-datepicker__year-text--in-range {
    background-color: $light-green;    
}

.react-datepicker__year .react-datepicker__day--selected:hover {
    background-color: $green;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.react-pdf__Page__annotations.annotationLayer {
    display: none !important;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100% !important;
}

.tox.tox-tinymce {
    transition: none !important;
}

.loader {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    border: .5em solid #eee;
    border-left: .5em solid $green;
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
    transition: opacity 0.3s;
  }
  
  .loader--hide {
    opacity: 0;
  }
  
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.chrome-picker {
    box-shadow: none !important;
    width: 100% !important;
}