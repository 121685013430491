@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.BuilderToolbar {
    position: fixed;
    top: $header-height;
    width: 100%;
    height: $header-height;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(95, 95, 122, 0.15);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    box-sizing: border-box;
    border-top: 1px solid #E3E8F1;
    z-index: 100;
}

.BuilderToolbar.Mobile {
    .ResumeActions {
        .ToolbarItem {
            margin: 0 .5rem;
            &:not(:last-child):after {
                display: none;
            }
        }
    }

    .ResumeTools {
        position: relative;
        flex-grow: 1;
        &:after {
            content: '';
            position: absolute;
            right: 0;
            display: inline-block;
            width: 1px;
            height: 14px;
            background-color: #E3E8F1;
            margin: 0 .5rem;
        }
    }
}

.ResumeActions,
.ResumeTools {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;

    .Button {
        display: flex;
        align-items: center;
        color: $primary-color;
        transition: all .3s ease;
        cursor: pointer;
        
        &:not(:last-child):after {
            content: '';
            display: inline-block;
            width: 1px;
            height: 14px;
            background-color: #E3E8F1;
            margin: 0 .5rem;
        }
    
        &:hover,
        &.Active {
            color: $green;
        }

        svg {
            display: inline-block;
            margin-right: .5rem;
            width: 1rem;
            color: inherit;
        }
    }

    .ToolbarItem {
        display: inline-flex;
        align-items: center;
        color: $primary-color;
        text-decoration: none;
        transition: all .3s ease;
    
        &:hover,
        &.Active {
            color: $green;
        }
    
        .ArrowIcon {
            width: 1em;
            margin: 0;
        }

        svg {
            display: inline-block;
            margin-right: .5rem;
            width: 1rem;
            color: inherit;
        }
    }
    
    .CircularProgress {
        margin-left: .5rem;
        color: $primary-color;
        font-weight: bold;
        font-size: 0.8rem;
        order: 1;

        svg {
            margin-right: 0;
            width: auto;
        }
    }
}

.DropdownItems {
    margin-top: 1.5rem;
    padding: .5rem .75rem;
    list-style: none;
    color:$light-grey;
    min-width: 200px;

    li {
        line-height: 1.5;
        padding: .25rem 0;
    }
}

.FontButton {
    background: #E3E8F1;
    border: 1px solid #E3E8F1;
    height: 50px;
    width: 60px;
    font-size: 1rem;
    color: $primary-color;
}

.FontInput {
    width: 60px;
    font-size: 1rem;
    color: $primary-color;
}


.ColorDropdown {
    min-width: 440px;
}

.ColorTabsWrap {
    border: 1px solid #E3E8F1;
    border-radius: 4px;
}

.ColorIcon {
    display: inline-block;
    margin-right: .5rem;
    box-sizing: border-box;
    width: 16px;
    height: 15px;
    border: 2px solid $dark-grey;
    border-radius: 50%;
}

.CustomColorToggle {
    display: flex;
    align-items: center;
    color: inherit;
    margin: 1rem 0;
    cursor: pointer;
    svg {
        width: .8em;
    }
}

.ResumeTypeItem {
    list-style: none;
    line-height: 2;
}

.ShareDropdown {
    width: 300px;
    font-size: 14px;    
}

.DownloadDropdown {
    .Button {
        padding: 5px;
        &:not(:last-child):after {
            display: none;
        }
    }
}

.FlexRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.Switch {
    margin: 0 0 0 auto;
}

.ShareLinkWrap {
    margin-top: 1rem;
}

.ShareLinkInput {
    padding: 1.1em 2.5em 1.1em 1em;
    margin-top: .25em;
    border-radius: 4px;
    font-size: 1em;
    border: 1px solid #E3E8F1;
    outline: none;
    color: $primary-color;
    width: 100%;
    background: url(../../assets/images/documet_icon.svg) no-repeat center right 10px;
    transition: all .5s ease;

    &:focus,
    &:hover,
    &:active {
        border-color: $primary-color;
    }
}

.ComplectionDropdown {
    width: 550px;
}

small {
    display: inline-block;
    color: $light-grey;
    font-size: .9rem;
    margin: .5em 0;
}

.CriteriaItem {
    display: flex;
    align-items: center;
    font-size: .8rem;
    margin: .5em 0;
    cursor: pointer;

    &.Complete {
        opacity: .3;
    }
}

.CriteriaLabel {
    margin: 0 5px 0 10px;
    color: $light-grey;
}

.CriteriaValue {
    color: $dark-grey;
    font-weight: bold;
}

.PatternsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    border: 1px solid #E3E8F1;
    border-radius: 4px;
    list-style: none;
    padding: .25rem;
    margin-top: .5rem;    
    max-width: 330px;
    
    .PatternItem {
        display: inline-block;
        margin: .25rem;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background: #E3E8F1;
        background-size: contain;

        &.Active {
            border: 2px solid $green;
        }
    }

    &.Mobile {
        flex-wrap: wrap;
        justify-content: space-evenly;
        .PatternItem {
            flex-basis: 30%;
            padding-top: 30%;
            border-radius: 50%;
            height: auto;
        }
    }


}

.DesignToolsDialog {
    box-shadow: none;

    .DialogTitle {
        display: flex;
        align-items: center;
        color: #fff;

        svg {
            color: #fff;
            margin-right: .5rem;
        }
    }

    .CloseDialogButton {
        top: 0;
        right: 0;
        padding: 0;
        svg {
            fill: #fff; 
        }
    }

    .Link {
        color: #E3E8F1;
    }

    .Button {
        min-width: 300px;
        margin: .5rem 0;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
    }

    .DialogContentWrap {
        background-color: #fff;
        border-radius: 4px;
        margin-top: .5rem;
        padding: 1rem;
        
        &.ButtonsWrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            padding: .5rem;
            color: $dark-grey;
    
            .IconButton {
                flex: 0 0 30%;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-decoration: none;
                padding: .75rem;
                cursor: pointer;
    
                svg,
                .ColorIcon {
                    width: 1.5rem;
                    width: 35px;
                    height: 35px;
                    margin-right: 0;
                    margin-bottom: 1rem;
                }
            }
        }

        .Button {
            display: block;
            min-width: auto;
            box-shadow: none;
            margin: 1rem;
        }
    }
}

.Button.Disabled {
    pointer-events: none;
    opacity: .7;
    cursor: none;
}