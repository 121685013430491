@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.Container {
    position: relative;
    margin: auto;
    max-width: 450px; 
    text-align: center;

    h3 {
        font-size: 1.5rem;
        line-height: 1.2;
        margin: 1em auto;
    }

    a {
        display: inline-block;
        color: #fff;
        margin: 0 auto;
        width: auto;
    }
}

.NotFoundImage {
    margin: 0 auto;
    max-width: 100%;
}

.NotFoundBgImage {
    position: absolute;
    left: 50%;
    bottom: -20%;
    transform: translateX(-50%);
    z-index: -1;
}

.Button {
    display: block;
    width: auto;
    text-transform: none;
    background-color: $green;
    padding: .7rem 1.5rem;
    font-size: 1.1rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    
    &:hover {
        background-color: #677089;
    }
}

@include breakpoint(mobile) {
    .Button {
        width: 100%;
        text-align: center;
    }
}

@include breakpoint(tablet) {
    .Container {
        h3 {
            font-size: 1.3rem;
        }
    }
    .NotFoundBgImage {
        max-width: 95%;
    }
}