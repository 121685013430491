@import '../../assets/styles/mixins.scss';

.Logo {
    display: inline-block;
    
    img {
        max-width: 150px;
    }
}

@include breakpoint(laptopDesktop) {
    .Logo {
        img {
            max-width: 120px;
        }
    }
}