@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.Container {
    position: relative;
    margin: auto;
    max-width: 415px;
}

.Paper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Form {
    width: 100%;
    margin: 1.5rem 0;
    background-color: #fff;
    color: $light-grey;
    padding: 20px;
    box-shadow: 0px 5px 8px rgba(86, 86, 109, 0.1);
    border-radius: 5px;
    text-align: center;

    h2 {
        text-align: left;
        margin-bottom: 1em;
    }

    .Button {
        margin: 0 auto;
    }
}

.ForgotLink {
    display: block;
    color: #636C84;
    text-align: right;
    margin-bottom: 1.5rem;
}

.SignUpLink {
    display: inline-block;
    font-weight: bold;
    text-decoration: none;
    margin-left: .5em;
    color: $green;
}
 
.LoginImage {
    position: absolute;
    left: 40%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: -1;
}

@include breakpoint(mobile) {
    .Form {
        padding: 20px 14px;
    }
}