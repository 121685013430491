@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.PDFViewer {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    text-align: center;
    min-height: 80vh;
    margin: 1rem auto;

    .Document {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .DocumentLoader {
        background: #fff;
        border-radius: 5px;
        padding: 50px;
        position: relative;
        width: calc(75vh * 0.7) !important;
    }

    img {
        width: 100%;
    }

    canvas {
        width: auto !important;
        height: auto !important;
        max-height: 75vh !important;
        max-width: 100% !important;
    }
}

.ResumeButtons {
    position: fixed;
    right: 50px;
    top: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
}

.PDFButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: $green;
    color: #fff;
    margin: .5rem 0;
    cursor: pointer;
    
    img {
        width: 25px;
    }
}