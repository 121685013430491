@import '../../../assets/styles/mixins.scss';

.DatepickerDropdown {
    z-index: 1000;
    opacity: 1;
    background: #fff;
    max-width: 370px;

    .FlexRow {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding: .5rem;

        &.MonthesRow {
            border-top: 1px solid #EEF1F8;
        }
    }

    .ControlsCell {
        flex: 0 0 21%;
        text-align: center;
        padding: .5rem .25rem;
    }

    .Cell {
        cursor: pointer;
        flex: 0 0 21%;
        padding: 1rem .25rem;
        text-align: center;
        border-radius: 3px;
        @include transition(all, .5s, ease);

        &:hover {
            background-color: #F7F9FD;
        }

        &.Active  {
            background-color: #19D390;
            color: #fff;
        }
    }

    .IconButton {
        padding: .25rem;
    }

    &.Disabled {
        .Cell,
        .ControlsCell {
            opacity: .5;
            pointer-events: none;
            filter: grayscale(50%);
        }
    }
}