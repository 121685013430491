@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.Header {
    height: $header-height;
    width: 100%;
    /* background-color: #FAFBFF; */
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(95, 95, 122, 0.15);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    box-sizing: border-box;
    z-index: 1000;


    .Logo {
        margin-right: 2rem;
    }

    .GeneralNavigation {
        flex-grow: 1;
    }

    &.BuilderHeader {
        width: 100%;
        position: fixed;
        top: 0;
        box-shadow: none;
    }

    /* &.BuilderHeader {
        width: 45%;

        .Logo {
            flex-grow: 1;
        }

        .GeneralNavigation {
            flex-grow: 0;
        }
    } */
}

.LogoHeader.Logo {
    margin: 1rem auto;
    text-align: center;
    img {
        max-width: 200px;
    }
}

@include breakpoint(desktopDown) {
    .DesktopOnly {
        display: none;
    }
}

@include breakpoint(laptopDesktop) {
    .Header {
        .Logo {
            margin-right: .5rem;
        }
    }
}