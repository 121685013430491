@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.Wrapper {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: stretch;
}

.ResumeForm {
    width: 45%;
    max-width: 45%;
    padding: 1.5rem 1.25rem 1.5rem;
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Logo {
    display: block;
    margin: 0 auto 2rem;
    text-align: center;
}

.Form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    margin: 1.5rem auto;
    background-color: #fff;
    color: $light-grey;
    padding: 20px;
    box-shadow: 0px 5px 8px rgba(86, 86, 109, 0.1);
    border-radius: 5px;
    text-align: center;
    
    h2 {
        width: 100%;
        text-align: left;
        margin-bottom: 1em;
    }

    .Input:not(:last-of-type) {
        width: 49%;
    }

    .Button {
        margin: 1.5rem auto 1rem;
        display: flex;
        align-items: center;
        svg {
            margin-left: .5rem;
        }
    }

    a {
        font-weight: normal;
        color: $green;
    }
}

.SignInLink {
    display: inline-block;
    font-weight: bold;
    text-decoration: none;
    margin-left: .5em;
    color: $green;
}

.ResumeResult {
    position: fixed;
    top: 0;
    right: 0px;
    width: 55%;
    background-color: #8C94B1;
    padding: 1.5rem 1.25rem;
    @include transition(all, .5s, ease);
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .ResultContent {
        position: relative;
        margin: 0 auto;
        flex-grow: 1;
    }
}

@include breakpoint(desktopDown) {
    .ResumeForm{
        width: 100%;
        max-width: 100%;
        min-height: auto;
    }

    .ResumeResult {
        position: relative;
        top: 50px;
        width: 100%;
        max-width: 100%;
        height: auto;
    } 
}