@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/mixins.scss';

.MobileToggle {
    position: relative;
    width: 32px;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;

    span {
        position: relative;
        display: block;
        width: 100%;
        height: 3px;
        background-color: $primary-color;
        @include transition(all, .3s, ease);
        backface-visibility: hidden;
        border-radius: 2px;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: -10px;
            width: 100%;
            height: 3px;
            background-color: $primary-color;
            @include transition(all, .3s, ease);
            backface-visibility: hidden;
            border-radius: 2px;
        }

        &:after {
            top: 10px;
        }
    }
    
    &.Open {
        span {
            background-color: transparent;
            text-align: right;

            &:before {
                width: 100%;
                transform: rotate(45deg) translate(6px, 8px);
            }
        
            &:after {
                width: 100%;
                transform: rotate(-45deg) translate(6px, -8px);
            }
        }
    }
}

@include breakpoint(desktop) {
    .MobileToggle {
        display: none;
    }
}