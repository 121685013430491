.MainContentCell {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    max-width: 65%;
    width: 65%;
    padding: 20pt;

    p {
        line-height: 1.3;
        margin-bottom: 0;
    }

    ul {
        margin: .5rem 0;
        line-height: 1.3;
    }
}

.SideHeader {
    text-align: center;
    margin-bottom: 15pt;
}

.SideItem {
    margin-bottom: 15pt;
    word-break: break-word;
    overflow-wrap: break-word;

    .SideSubtitle {
        margin-top: 7pt;
        margin-bottom: 3pt;
        font-weight: 700;
    }

    .SideRepeatedItem {
        padding-bottom: 7pt;
        line-height: 1.7;

        .Level {
            height: 13px;
        }
    }
}

.ContactItem {
    display: flex;
    margin: 5px 0;
    word-break: break-all;

    svg {
        margin-right: 0.5rem;
    }
}

.ContactLine {
    margin-bottom: 7pt;

    span {
        display: inline-block;

        &:not(:last-of-type)::after {
            display: inline-block;
            margin-right: 0.5em;
            content: ",";
        }

        &.PhoneNumber {
            white-space: nowrap;
        }
    }
}

.SectionIcon {
    width: 15pt;
    height: 15pt;
    object-fit: contain;
    margin-top: 3pt;
    margin-right: 5pt;
}

.SectionWrap {
    margin-bottom: 5pt;
}

.SectionTitleWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-grow: 1;
    flex-shrink: 0;
    margin-bottom: 5pt;

    > svg {
        order: 1;
    }
}

.RepeatedItem {
    margin-bottom: 5pt;

    .Title {
        font-weight: 700;
    }
}

.ProgressLine {
    width: '100%';
    height: 10pt;
}