@import '../../assets/styles/variables.scss';

.Layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: calc(100vh - 60px);
    padding: 50px 0;
    box-sizing: border-box;
    max-width: 100vw;
    overflow: hidden;

    &.SignUpLayout {
        padding: 0;
        background: $bg-color;
    }

    &.ResumeBuilderLayout {
        padding: 0;
        background: $bg-color;
    }
}