@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.Wrapper {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: stretch;
}

.ResumeForm {
    width: 45%;
    max-width: 45%;
    /* padding: 10px 2.5rem 2.5rem;
    position: relative; */
    
    /* padding: 2.5rem;
    top: calc(#{$header-height} * 2); */
    
    padding: calc(#{$header-height} * 2 + 1.5rem) 1.25rem 2.5rem;
    position: relative;
/*  max-height: calc(100vh - 60px);
    overflow-y: scroll; */

    .Form {
        margin: 0 auto;
    }

    .FormSectionWrap {
        @include transition(all, .5s, ease);
        padding-bottom: 1.5rem;
        opacity: .5;
    }

    .FormSection {
        position: relative;
        box-shadow: 0px 1px 4px rgba(28, 28, 89, 0.08);
        padding: 1.5rem;
        margin: 0;

        &.GeneralInfo {
            padding-top: 0;
            margin-top: 60px;
        }
    }

    .TitleInput {
        input {
            border: none;
            border-bottom: 2px solid transparent;
            outline: none;
            font-size: 1.4rem;
            font-family: $font-stack;
            background: transparent;
            color: $primary-color;
            @include transition(border-color, .3s, ease);

            &:focus {
                border-color: $green;
            }
        }

        &.CustomSectionTitle {
            margin-right: 1rem;
            input {
                font-size: 1.14rem;
                font-weight: bold;
            }
        }
    }
}

.AvatarUploader {
    /* position: absolute;
    top: -55px;
    left: 50%;
    transform: translateX(-50%); */
    text-align: center;
    position: relative;
    top: -35px;

    .AvatarInput {
        display: none;
    }

    .AvatarLabel {
        display: inline-block;
        cursor: pointer;
        position: relative;
        color: $dark-grey;
        font-weight: bold;
        border: 1.5px solid $dark-grey;
        border-radius: 4px;
        padding: .6em .75em;
    }

    .AvatarImage {
        width: 110px;
        height: 110px;
        margin: 0 auto;
        background: #E9EBF5;
        position: relative;
        top: -15px;
    }

    .AvatarIcon {
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: #fff;
        fill: #10b58c;
        border-radius: 50%;
    }
}

.Datepicker {
    margin: .5rem 0;
    label {
        position: relative;
        text-align: left;
        transform: translate(0, -6px) !important;
        color: $light-grey;
        font-family: $font-stack;
        margin-bottom: 0.25em;
    }
    input {
        padding: 14px 14px 17px !important;
    }
    legend {
        display: none;
    }
}

.ResumeResult {
    position: fixed;
    /* top: calc(#{$header-height} * 2); */
    top: 0;
    right: 0px;
    width: 55%;
    background-color: #8C94B1;
    padding: 10px 2.5rem;
    padding-top: calc(#{$header-height} * 2);
    @include transition(all, .5s, ease);
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;

    /* &.Fixed {
        top: 0;
    } */

    .ResultContent {
        position: relative;
        margin: 0 auto;
        flex-grow: 1;
    }

    .ResumeInfo {
        /* display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center; */
        display:inline-block;
        margin: 1rem auto 0;
        /* position: sticky;
        left: 10px;
        top: calc(100vh - 150px);  */   
    }

    .ResumeStatus {
        display: inline-flex;
        align-items: center;
        color: #fff;
        position: relative;
        left: -20px;

        &.NotAuth {
            color: #fb645d;

            span {
                display: inline-block;
                margin-left: .25rem;
            }
        }
    }
}

.FormSection {
    padding: 1rem;
    margin-bottom: 1rem;
    color: $light-grey;

    .PanelToggle {
        float: right;
        padding: 0;
    }
}

.ExtraFormSection {
    box-shadow: 0px 1px 4px rgba(28, 28, 89, 0.08);
    display: flex;
    align-items: center;
    padding: 1rem 1.2rem;
    cursor: pointer;

    &.Opened {
        opacity: .7;
        filter: grayscale(50%);
        pointer-events: none;
    }

    img {
        max-width: 18px;
        margin-right: 18px;
    }
}

.PanelTitle {
    display: inline-flex;
    align-items: center;
    margin-bottom: 1.4rem;
    h4 {
        margin-right: 1rem;
        color: $primary-color;
        margin-bottom: 0;
    }
    .TooltipIcon {
        fill: #5C6783;
    }
}

.Tooltip {
    max-width: 225px;
    font-size: 0.9rem;
    background: #5C6783;
    border-radius: 5px;
    padding: 10px 14px;
}

.FormLink {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $light-green;
    text-align: center;
    font-size: 1rem;
    margin-top: 1.5rem;
    cursor: pointer;

    svg {
        margin-right: .5rem;
    }
}

.FormRepeatedItem {
    position: relative;
    background-color: #fff;
    border-top: 1px solid #F7FAFF;
    padding-top: .5rem;
    margin-top: 1rem;

    .ResumeItemTitle {
        font-weight: normal;
        margin-bottom: 0.25rem;
    }

    .ResumeItemDates {
        font-size: .9rem;
        color: $light-grey;
        margin-bottom: 0.25rem;
    }

    &:hover,
    &:focus,
    &:active {
        .DragButton,
        .DeleteButton {
            opacity: 1;
            visibility: visible;
        }
    }

    .DragButton,
    .DeleteButton {
        display: inline-block;
        opacity: 0;
        visibility: hidden;
        @include transition(all, .3s, ease);
    }

    .ItemTitle {
        cursor: pointer;    
        flex: 0 1 auto;
    }

    .ItemControls {
        flex: 0 0 auto;
    }
}

.Label {
    display: flex;
    align-items: center;
    color: $light-grey;
    font-family: $font-stack;
    margin-bottom: 5px;
}

.ToggleLink {
    display: flex;
    margin-top: .75rem;
    padding: .25rem 0;
    color: $light-green;
    cursor: pointer;
    @include transition(all, .3s, ease);

    &:hover {
        color: $green;
    }
}

.DeleteIconWrap {
    align-self: flex-end;
    margin: .5rem 0;
    text-align: center;
}

.DatepickedInput {
    padding: 17px 14px;
    border: 1px solid #E3E8F1;
    outline: none;
    border-radius: 5px;
    width: auto;
    max-width: 100%;
    
    &:hover,
    &:focus {
        border: 1px solid $primary-color;
    }
}

.DatepickedCalendar {
    button {
        outline: none;
    }
}

.BithdayCalendar {
    font-family: $font-stack;
    box-shadow: 0px 4px 10px rgba(28, 28, 89, 0.15);
    border: none !important;
    border-radius: 4px;

    & :global .react-datepicker__current-month,
    & :global button.react-datepicker__navigation {
        display: none;
    }

    & :global .react-datepicker__header {
        border-bottom: none;
        padding-bottom: 0;

        .react-datepicker__day-name {
            color: #B1BFD7;
            font-size: 0.8rem;
            line-height: 1;
            margin-top: 1rem;    
            width: 40px;
        }
    }

    & :global .react-datepicker__header__dropdown {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;

        select {
            border: 1px solid #E3E8F1;
            border-radius: 3px;
            padding: .5rem 1rem;
            outline: none;

            &:hover,
            &:focus {
                border-color: $primary-color;
            }
        }
    }

    & :global .react-datepicker__month {
        .react-datepicker__day--outside-month {
            color: #B1BFD7;
        }

        .react-datepicker__day {
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 1rem;
        }

        .react-datepicker__day--selected, 
        .react-datepicker__day--in-selecting-range, 
        .react-datepicker__day--in-range, 
        .react-datepicker__month-text--selected, 
        .react-datepicker__month-text--in-selecting-range, 
        .react-datepicker__month-text--in-range, 
        .react-datepicker__quarter-text--selected, 
        .react-datepicker__quarter-text--in-selecting-range, 
        .react-datepicker__quarter-text--in-range, 
        .react-datepicker__year-text--selected, 
        .react-datepicker__year-text--in-selecting-range, 
        .react-datepicker__year-text--in-range,
        .react-datepicker__day--keyboard-selected, 
        .react-datepicker__month-text--keyboard-selected, 
        .react-datepicker__quarter-text--keyboard-selected, 
        .react-datepicker__year-text--keyboard-selected {
            background: #19D390;
            border-radius: 3px;
            color: #fff;
        }
    }

    button {
        outline: none;
    }
}

.DatepickedCalendarDisabled {
    & :global .react-datepicker__month-container {
        pointer-events: none;
        filter: grayscale(100%);
        opacity: .5;
    }
}

.FocusWrap {
    section:hover,
    section:focus {
        opacity: 1 !important;
    }
}



.TemplatesSwitcher {
    display: none;
    width: 45%;
    max-width: 45%;
    padding: calc(#{$header-height} * 2 + 1.5rem) 1.25rem 2.5rem;
    position: relative;
    top: 0;
    left: -10000%;

    &.Open {
        display: block;
        left: 0;
    }


    .Link {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        font-size: 0.9rem;
        color: $green;
        text-decoration: none;
    }

    .TemplatesList {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        
        .TemplateItem {
            flex: 0 1 48%;
            margin: 1rem 0;
            cursor: pointer;

            &.Current {
                pointer-events: none;
                border: 2px solid $green;
            }

            img {
                max-width: 100%;
            }
        }
    }
}

.Hidden {
    display: none;
}

.SampleMark {
    color: $primary-color;
    font-size: .9rem;
}

.SuggestionOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ResumeSuggestion {
    display: flex;
    background-color: #F5F6FC;
    color: $dark-grey;
    border-radius: 5px;
    /* max-height: 0; */
    margin: 1rem 0 0;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    @include transition(max-height, .3s, ease);

    /* &.Open {
        max-height: 100%;
        padding: 1rem;
    }
 */
    h5 {
        font-size: 1rem;
    }

    p {
        margin-bottom: 1rem;
    }

    .Button {
        font-size: 1rem;
    }

    .Link {
        color: $light-grey;
        text-decoration: underline;
        cursor: pointer;
    }
}

.ResumeSuggestionImage {
    height: 0;
    padding-bottom: 141%;
    background-size: cover;
    background-repeat: no-repeat;
}

@include breakpoint(tablet) {
    .ResumeSuggestion {
        padding: 1rem .5rem;
        font-size: .95rem;

        svg {
            margin-right: .25rem !important;
        }

        h5 {
            font-size: 0.95rem;
        }

        .Button {
            font-size: .5rem .7rem;
            font-size: 0.95rem;
        }

        .Link {
            font-size: .8rem;
        }
    }
}


@include breakpoint(desktopDown) {
    .MobileCtaButton {
        display: block;
        position: fixed;
        bottom: 10%;
        width: 80%;
        max-width: 330px;
        left: 50%;
        transform: translateX(200%);
        z-index: 10;    
        @include transition(all, .5s, ease);


        &.Visible {
            transform: translateX(-50%);
        }
    }

    .DownloadButton {
        display: block;
        width: 100%;
        max-width: 330px;
        margin: 1rem auto;

        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .CircleButton {
        display: block;
        position: fixed;
        bottom: 10%;
        right: 10%;
        z-index: 10;
        background-color: $green !important;

        &.Hidden {
            display: none;
        }
    }

    .ResumeForm,
    .TemplatesSwitcher {
        width: 100%;
        max-width: 100%;

        &.MobileHidden {
            display: none;
        }
    }
    
    .ResumeResult {
       /*  display: none; */
        top: 0px;
        left: -10000%;
        width: 100%;
        z-index: 100;
        padding: 0;
        text-align: center;

        .ViewerControls {
            display: none;
        }

        &.Visible {
            /* display: flex; */
            left: 0;
            z-index: 103;

            .ViewerControls {
                display: block;
            }
        }

        .ResumeInfo {
            height: $header-height;
            width: 100%;
            background-color: #FAFBFF;
            box-shadow: 0 2px 4px rgba(95, 95, 122, 0.15);
            padding: 0 2rem;
            box-sizing: border-box;
            z-index: 101;
            position: sticky;
            top: 0;
            display: flex;
            justify-content: space-between;

            .ResumeStatus {
                display: flex;
                color: $primary-color;
                left: 0px;
            }
        }

        .ResultContent {
            position: relative;
            padding: 10px 2.5rem;
            width: 100%;
        }
    }
}

.Dialog {
    .DialogTitle {
        color: #fff;
        text-align: center;
        font-size: 1.3rem;
        margin-bottom: 1em;
        span {
            font-weight: lighter;
        }
    }

    .DialogImageWrap {
        position: relative;
        height: calc(100vh - 150px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* background-repeat: no-repeat;
        background-position: center; */

        img {
            display: block;
            max-height: 100%;
            max-width: 100%;
            margin: 0 auto;
        }

        .Button {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 20%;
        }
    }

    .CloseDialogButton {
        position: absolute;
        top: 10px;
        right: 10px;
    
        svg {
            fill: #E3E8F1;
        }
    }

    .Link {
        color: #E3E8F1;
        text-decoration: underline;
        cursor: pointer;
        text-align: center;
    }
}

@include breakpoint(tablet) {
    .Dialog {
        .DialogTitle {
            text-align: left;
            font-size: 1.14rem;
        }
    
        .DialogImageWrap {
            .Button {
                position: static;
                transform: translateX(0);
                width: 100%;
            }
        }
    }
}

@include breakpoint(mobile) {
    .MobileCtaButton {
        width: inherit;
        max-width: calc(100% - 2rem);
    }

    /* .ResumeForm,
    .TemplatesSwitcher {
        padding: 10px 1rem;
    }

    .ResumeResult {
        .ResumeInfo,
        .ResultContent {
            padding: 10px 1rem;
        }
    } */
}

@include breakpoint(desktop) {
    .MobileCtaButton,
    .DownloadButton,
    .ResumeCloseButton,
    .CircleButton {
        display: none;
    }
}

.ShareDialog {
    .Button {
        align-self: center;
    }

    .LinkInput {
        margin: 1rem 0;
        border: 0;
        outline: none;
        font-size: 1rem;
        line-height: 2;
        border-bottom: 1px solid $green;

        &:hover,
        &:active {
            border-bottom: 1px solid $light-green;
        }
    }

    p.Status {
        text-align: center;
        font-size: .9rem;
        color: $green;
        margin-top: .5rem;
    }
}